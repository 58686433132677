import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/style.css';

/* no-use-before-define */
import $ from 'jquery';

const ajaxBase = 'public/ajax.php'; // http://localhost/git/wh/website-2020/
const anchors = [].slice.call(document.querySelector('.menuitems').children);
const pages = [].slice.call(document.querySelector('#Main').children);
const references = [].slice.call(document.querySelector('#Referenzen').children);

let currentArea = 'Home';
let currentPage = 'Home';
let currentSite = '#Main';
let user;
let token;
let autoLogin;
let tableJson;
let sort = 'file';
let sortAsc = true;

let blueAnimationTimer;

const headerElement = document.getElementById('Header');

const cubeVideoDesktop = document.getElementById('CubeVideoDesktop');
const cubeVideoMobile = document.getElementById('CubeVideoMobile');
const messeVideoDesktop = document.getElementById('MesseVideoDesktop');
const messeVideoMobile = document.getElementById('MesseVideoMobile');
const leistungenVideoDesktop = document.getElementById('LeistungenVideoDesktop');
const referenzenVideo33IO = document.getElementById('ReferenzenVideo33IO');
const referenzenVideoIScreen = document.getElementById('ReferenzenVideoIScreen');
const referenzenVideoRepha = document.getElementById('ReferenzenVideoRepha');
const referenzenVideo4 = document.getElementById('ReferenzenVideo4');
const referenzenVideo5 = document.getElementById('ReferenzenVideo5');
const referenzenVideo6 = document.getElementById('ReferenzenVideo6');
let referenzenVideo6PlayCounter = 0;

referenzenVideo5.addEventListener(
  'ended',
  () => {
    $('#reload-ReferenzenVideo5').show();
  },
  false
);

referenzenVideo6.addEventListener(
  'ended',
  () => {
    if (referenzenVideo6PlayCounter < 1) {
      referenzenVideo6.currentTime = 0;
      referenzenVideo6PlayCounter += 1;
      referenzenVideo6.play();
    } else {
      $('#reload-ReferenzenVideo6').show();
      referenzenVideo6PlayCounter = 0;
    }
  },
  false
);

referenzenVideo33IO.addEventListener(
  'ended',
  () => {
    $('#reload-ReferenzenVideo33IO').show();
  },
  false
);

referenzenVideoIScreen.addEventListener(
  'ended',
  () => {
    $('#reload-ReferenzenVideoIScreen').show();
  },
  false
);

referenzenVideoRepha.addEventListener(
  'ended',
  () => {
    $('#reload-ReferenzenVideoRepha').show();
  },
  false
);

let isLandscape = window.innerWidth > 640;
let pageTrigger = headerElement.clientHeight;

function handleWindowResize() {
  isLandscape = window.innerWidth > 640; // > window.innerHeight;
  pageTrigger = isLandscape ? headerElement.clientHeight : window.innerHeight / 2;
  // mainElement.style.top = headerElement.clientHeight * 0.85 + 'px';
}

/*
const pageable = new Pageable('main', {
  pips: false,
  animation: 400,
  delay: 300,
  onBeforeStart: function () {
    this.pages.forEach((page, i) => {
      page.classList.remove('pg-active');
    });
  },
  onInit: initScroller,
  onFinish: handleScrollFinished,
});

function initScroller(data) {
  anchors.forEach(function (anchor, i) {
    anchor.onclick = function () {
      pageable.scrollToAnchor(anchor.firstElementChild.getAttribute('href'));
    };
  });
  handleScrollFinished(data);
}
*/

function playAnimations() {
  $('.animated').removeClass('playing');
  $(`#${currentPage} .animated`).addClass('playing');
  $(`#${currentPage}Page .animated`).addClass('playing');

  if (currentPage === 'Leistungen' && !blueAnimationTimer) {
    $('#Blue-2').fadeTo(0, 0);
    $('#Blue-Mobil-2').fadeTo(0, 0);
    $('#Blue-3').fadeTo(0, 0);
    $('#Blue-Mobil-3').fadeTo(0, 0);
    $('#Blue-4').fadeTo(0, 0);
    $('#Blue-Mobil-4').fadeTo(0, 0);

    blueAnimationTimer = window.setTimeout(() => {
      $('#Blue-2').fadeTo(200, 1);
      $('#Blue-Mobil-2').fadeTo(200, 1);
    }, 2000);
    window.setTimeout(() => {
      $('#Blue-3').fadeTo(100, 1);
      $('#Blue-Mobil-3').fadeTo(100, 1);
    }, 2200);
    window.setTimeout(() => {
      $('#Blue-4').fadeTo(200, 1);
      $('#Blue-Mobil-4').fadeTo(200, 1);
    }, 2300);
  }

  if (currentPage !== 'Leistungen' && blueAnimationTimer) {
    blueAnimationTimer = undefined;
    $('#Blue-2').fadeTo(500, 0);
    $('#Blue-Mobil-2').fadeTo(500, 0);
    $('#Blue-3').fadeTo(500, 0);
    $('#Blue-Mobil-3').fadeTo(500, 0);
    $('#Blue-4').fadeTo(500, 0);
    $('#Blue-Mobil-4').fadeTo(500, 0);
  }
}

function playVideo() {
  if (currentPage === 'Kommunikation') {
    if (isLandscape) {
      cubeVideoDesktop.play();
    } else {
      cubeVideoMobile.play();
    }
  } else if (isLandscape) {
    cubeVideoDesktop.pause();
    cubeVideoDesktop.currentTime = 0;
  } else {
    cubeVideoMobile.pause();
    cubeVideoMobile.currentTime = 0;
  }

  if (currentPage === 'Messe') {
    if (isLandscape) {
      messeVideoDesktop.play();
    } else {
      messeVideoMobile.play();
    }
  } else if (isLandscape) {
    messeVideoDesktop.pause();
    messeVideoDesktop.currentTime = 0;
  } else {
    messeVideoMobile.pause();
    messeVideoMobile.currentTime = 0;
  }

  if (currentPage === 'Digital') {
    leistungenVideoDesktop.play();
  } else {
    leistungenVideoDesktop.pause();
    leistungenVideoDesktop.currentTime = 0;
  }

  if (currentPage === 'Referenz-6') {
    $('#reload-ReferenzenVideo6').hide(0);
    referenzenVideo6.play();
  } else {
    referenzenVideo6.pause();
    referenzenVideo6.currentTime = 0;
    referenzenVideo6PlayCounter = 0;
  }

  if (currentPage === 'Referenz-5') {
    $('#reload-ReferenzenVideo5').hide(0);
    referenzenVideo5.play();
  } else {
    referenzenVideo5.pause();
    referenzenVideo5.currentTime = 0;
  }

  if (currentPage === 'Referenz-4') {
    referenzenVideo4.play();
  } else {
    referenzenVideo4.pause();
    referenzenVideo4.currentTime = 0;
  }

  if (currentPage === 'Referenz-33IO') {
    $('#reload-ReferenzenVideo33IO').hide(0);
    referenzenVideo33IO.play();
  } else {
    referenzenVideo33IO.pause();
    referenzenVideo33IO.currentTime = 0;
  }

  if (currentPage === 'Referenz-IScreen') {
    $('#reload-ReferenzenVideoIScreen').hide(0);
    referenzenVideoIScreen.play();
  } else {
    referenzenVideoIScreen.pause();
    referenzenVideoIScreen.currentTime = 0;
  }

  if (currentPage === 'Referenz-Repha') {
    $('#reload-ReferenzenVideoRepha').hide(0);
    referenzenVideoRepha.play();
  } else {
    referenzenVideoRepha.pause();
    referenzenVideoRepha.currentTime = 0;
  }
}

function updatePage() {
  if (currentArea === 'Main') currentArea = 'Home';
  if (currentArea === 'Referenzen') currentPage = 'Referenz-6';
  anchors.forEach((anchor) => {
    if (anchor.firstElementChild.getAttribute('href')) {
      anchor.firstElementChild.classList.toggle('active', anchor.firstElementChild.getAttribute('href').substring(1) === currentArea);
    }
  });
  document.getElementById('Header-Menu').classList.toggle('inactive', $('.menuitem.active').length === 0);
  playAnimations();
  playVideo();
}

function handleScrollFinished() {
  headerElement.classList.toggle(
    'fixed',
    currentSite === '#Kundenbereich' || currentSite === '#Main-Kontakt' || $(window).scrollTop() >= 100
  );
  if (currentSite === '#Main') {
    let found;
    for (let i = 0; i < pages.length && !found; i += 1) {
      const dif = pages[i].offsetTop - $(window).scrollTop();
      if (dif >= 0 && dif < pageTrigger) found = pages[i];
    }
    if (!found) {
      for (let i = 0; i < pages.length && !found; i += 1) {
        const dif = Math.abs(pages[i].offsetTop - $(window).scrollTop());
        if (dif >= 0 && dif < pageTrigger) found = pages[i];
      }
    }
    if (found && found.getAttribute('data-anchor') && found.getAttribute('data-anchor') !== currentPage) {
      currentArea = found.getAttribute('data-area');
      currentPage = found.getAttribute('data-anchor');
      if (window.history.pushState) {
        window.history.pushState(null, null, `#${currentPage}`);
      } else {
        window.location.hash = `#${currentPage}`;
      }
      updatePage();
    }
  } else if (currentSite === '#Referenzen') {
    let found;
    for (let i = 0; i < references.length && !found; i += 1) {
      const dif = references[i].offsetTop - $(window).scrollTop();
      if (dif >= 0 && dif < window.innerHeight / 2) found = references[i];
    }
    if (!found) {
      for (let i = 0; i < references.length && !found; i += 1) {
        const dif = Math.abs(references[i].offsetTop - $(window).scrollTop());
        if (dif >= 0 && dif < pageTrigger) found = references[i];
      }
    }
    if (found && found.getAttribute('data-anchor') && found.getAttribute('data-anchor') !== currentPage) {
      currentArea = found.getAttribute('data-area');
      currentPage = found.getAttribute('data-anchor');
      updatePage();
    }
  } else {
    currentArea = currentSite.substring(1);
    currentPage = currentSite.substring(1);
    updatePage();
  }
}

function showPage(page) {
  currentSite = page;
  $('.main').hide();
  $(currentSite).show();
  window.setTimeout(() => {
    $(currentSite).show();
    updatePage();
  }, 200);
  if (currentSite === '#Kundenbereich') {
    headerElement.classList.add('fixed');
    if (autoLogin) {
      doLogin(decodeURIComponent(autoLogin[1]), decodeURIComponent(autoLogin[2]));
      autoLogin = undefined;
    }
  }
  if (currentSite === '#Main-Kontakt') headerElement.classList.add('fixed');
  if (currentSite === '#Main' || currentSite === '#Referenzen') $('#FooterFixed').hide();
  else $('#FooterFixed').show();
  currentArea = currentSite.substring(1);
  currentPage = currentSite.substring(1);
}

function handleClick(e) {
  if (e.currentTarget.getAttribute('page') !== currentSite) {
    showPage(e.currentTarget.getAttribute('page'));
  }
}

function handlePersonOver(e) {
  e.currentTarget.classList.toggle('active', e.originalEvent.type !== 'mouseout');
}

function acceptCookies() {
  $('#CookieBanner').hide(500, () => {
    document.body.removeChild(document.getElementById('CookieBanner'));
  });
  const expires = new Date(new Date().getTime() + 30 * 1000 * 60 * 60 * 24);
  document.cookie = `cookie_notice_accepted=true;path=/;expires=${expires.toGMTString()}`;
}

function ajaxError(/* jqxhr, textStatus, error */) {
  // const err = `${textStatus}, ${error}`;
  // console.error('Request Failed', err);
  $('#UploadButton').show();
  $('#UploadSpinner').hide();
  $('#UploadProgress').hide();
}

function handleUpload() {
  $('#UploadButton').hide();
  $('#UploadSpinner').show();
  $('#UploadProgressBar').width(0);
  $('#UploadProgress').show();
  const fileData = $('#uploadFile').prop('files')[0];
  const formData = new FormData();
  formData.append('cmd', 'upload');
  formData.append('user', user);
  formData.append('token', token);
  formData.append('file', fileData);
  $.ajax({
    url: ajaxBase,
    cache: false,
    contentType: false,
    processData: false,
    data: formData,
    type: 'post',
    xhr: () => {
      const xhr = $.ajaxSettings.xhr();
      xhr.upload.onprogress = (e) => {
        if (e.lengthComputable) {
          const progress = Math.ceil((e.loaded * 100) / e.total);
          $('#UploadProgressBar').attr('aria-valuenow', progress);
          $('#UploadProgressBar').width(`${progress}%`);
        }
      };
      return xhr;
    },
  })
    .done(() => {
      $('#UploadButton').show();
      $('#UploadSpinner').hide();
      $('#UploadProgress').hide();
      getKundenFiles();
    })
    .fail(ajaxError);
}

function doLogin(userName, pwd) {
  if (!userName || !pwd) return;
  $.getJSON(ajaxBase, {
    user: userName,
    pwd,
    cmd: 'login',
  })
    .done((result) => {
      if (result.status === 'ok') {
        user = userName;
        token = result.token; // eslint-disable-line
        $('#loginKunde').val('');
        $('#loginPasswort').val('');
        $('#Kundenbereich-Login').hide();
        if (user === 'admin') {
          sort = 'name';
          sortAsc = true;
          getKunden();
        } else {
          sort = 'file';
          sortAsc = true;
          getKundenFiles();
        }
      }
    })
    .fail(ajaxError);
}

function handleLogin() {
  doLogin($('#loginKunde').val(), $('#loginPasswort').val());
}

function handleLogout() {
  $.getJSON(ajaxBase, {
    cmd: 'logout',
  }).done((result) => {
    if (result.status === 'OK') {
      user = 'admin';
      sort = 'name';
      sortAsc = true;
      getKunden();
    } else {
      user = undefined;
      token = undefined;
      $('#loginKunde').val('');
      $('#loginPasswort').val('');
      $('#Kundenbereich-Dateien').hide();
      $('#Kundenbereich-Files').empty();
      $('#Kundenbereich-Kunden').hide();
      $('#Kundenbereich-Customers').empty();
      $('#Kundenbereich-Login').show();
    }
  });
}

function getKundenFiles(action, file) {
  $('#Kundenbereich-Kunden').hide();
  $('#Kundenbereich-Customers').empty();
  $('#Kundenbereich-Files').empty();
  $('#Kundenbereich-Dateien').show();
  $.getJSON(ajaxBase, {
    user,
    token,
    cmd: action || 'getfiles',
    file: file || '',
  })
    .done(createFileList)
    .fail(ajaxError);
}

function createFileList(json) {
  if (json) tableJson = json;
  if (sort === 'date') tableJson.sort((a, b) => (sortAsc ? a.date - b.date : b.date - a.date));
  else tableJson.sort((a, b) => (sortAsc ? a[sort].localeCompare(b[sort]) : b[sort].localeCompare(a[sort])));

  $('#Kundenbereich th.sort').removeClass('sortUp').removeClass('sortDown');
  $(`#Kundenbereich [data-sort="${sort}"]`).addClass(sortAsc ? 'sortUp' : 'sortDown');
  $('#Kundenbereich-Files').empty();
  $.each(tableJson, (i, record) => {
    const date = new Date(record.date * 1000);
    $('#Kundenbereich-Files').append(
      $(
        `<tr><th class="download" scope="row" data-file="${record.file}">Download</th><td>${record.file}</td><td>${`${
          date.getDate() < 10 ? '0' : ''
        }${date.getDate()}.${date.getMonth() + 1 < 10 ? '0' : ''}${
          date.getMonth() + 1
        }.${date.getFullYear()}`}</td><td class="trash" data-file="${record.file}">&times;</td></tr>`
      )
    );
  });

  $('#Kundenbereich-Files').append(
    $(
      '<tr><th class="addFile" scope="row"><label id="UploadButton" for="uploadFile" class="col-form-label">Datei hinzufügen</label><div id="UploadSpinner" class="spinner-border" role="status"><span class="sr-only">Wird hochgeladen...</span></div><input type="file" class="form-control-plaintext custom-file-input" id="uploadFile" /></th><td><div id="UploadProgress" class="progress"><div id="UploadProgressBar" class="progress-bar" role="progressbar" style="width: 0%" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div></div></td><td></td><td></td></tr>'
    )
  );
  // $('#Kundenbereich-Files').append($('<tr><th class="addLink" scope="row">Link hinzufügen</th><td></td><td></td><td></td></tr>'));

  $('.download').on('click', (event) => {
    const url = `${ajaxBase}?cmd=download&user=${user}&token=${token}&file=${$(event.target).attr('data-file')}`;
    window.open(url, '_blank');
  });

  $('.trash').on('click', (event) => {
    getKundenFiles('delfile', encodeURIComponent($(event.target).attr('data-file')));
  });

  $('#uploadFile').change(handleUpload);
}

function getKunden(action, newCustomer, newPassword) {
  $('#Kundenbereich-Dateien').hide();
  $('#Kundenbereich-Files').empty();
  $('#Kundenbereich-Customers').empty();
  $('#Kundenbereich-Kunden').show();
  $.getJSON(ajaxBase, {
    user,
    token,
    cmd: action || 'getusers',
    customer: newCustomer || '',
    password: newPassword || '',
  })
    .done(createCustomerList)
    .fail(ajaxError);
}

function randomPassword(length) {
  const chars = 'abcdefghijklmnopqrstuvwxyz!.-_ABCDEFGHIJKLMNOP1234567890';
  let pass = '';
  for (let x = 0; x < length; x += 1) {
    const i = Math.floor(Math.random() * chars.length);
    pass += chars.charAt(i);
  }
  return pass;
}

function copyToClipboard(str) {
  const el = document.createElement('textarea');
  el.value = str;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
}

function createCustomerList(json) {
  if (json) tableJson = json;
  tableJson.sort((a, b) => (sortAsc ? a[sort].localeCompare(b[sort]) : b[sort].localeCompare(a[sort])));
  $('#Kundenbereich th.sort').removeClass('sortUp').removeClass('sortDown');
  $(`#Kundenbereich [data-sort="${sort}"]`).addClass(sortAsc ? 'sortUp' : 'sortDown');
  $('#Kundenbereich-Customers').empty();
  $.each(tableJson, (i, customer) => {
    let link = window.location.href;
    link = link.substring(0, link.lastIndexOf('/'));
    link = `${link}/#Kundenbereich/${encodeURIComponent(customer.name)}/${encodeURIComponent(customer.pwd)}`;
    $('#Kundenbereich-Customers').append(
      $(
        `<tr><td class="customer" data-name="${customer.name}" data-pwd="${customer.pwd}">${customer.name}</td><td class="link" data-link="${link}">&#128279</td><td class="trash" data-customer="${customer.name}">&times;</td></tr>`
      )
    );
  });

  $('#Kundenbereich-Customers').append($('<tr><td colspan="3" class="empty">&nbsp;</td></tr>'));

  $('#Kundenbereich-Customers').append(
    $('<tr><td colspan="3"><input type="text" id="customerName" class="form-control-plaintext" placeholder="Neuer Benutzer" /></td></tr>')
  );

  $('#Kundenbereich-Customers').append(
    $(
      `<tr><td colspan="3"><input type="text" id="customerPassword" class="form-control-plaintext" placeholder="Neues Passwort" value="${randomPassword(
        12
      )}" /></td></tr>`
    )
  );
  $('#Kundenbereich-Customers').append($('<tr><th colspan="3" class="createCustomer" scope="row">Benutzer hinzufügen</th></tr>'));

  $('.customer').on('click', (event) => {
    doLogin($(event.target).attr('data-name'), token);
  });

  $('.link').on('click', (event) => {
    copyToClipboard($(event.target).attr('data-link'));
    alert('Der Link zum personalisierten Kundenzugang wurde in die Zwischenablage kopiert'); // eslint-disable-line
  });

  $('.trash').on('click', (event) => {
    getKunden('deluser', encodeURIComponent($(event.target).attr('data-customer')));
  });

  $('.createCustomer').click(handleCreateCustomer);
}

function handleSort(e) {
  const newSort = e.currentTarget.getAttribute('data-sort');
  if (newSort !== sort) {
    sort = newSort;
    sortAsc = true;
  } else {
    sortAsc = !sortAsc;
  }

  if (user === 'admin') {
    createCustomerList();
  } else {
    createFileList();
  }
}

function handleCreateCustomer() {
  $('#customerName').removeClass('error');
  $('#customerPassword').removeClass('error');

  if ($('#customerName').val() && $('#customerPassword').val()) {
    getKunden('createuser', $('#customerName').val(), $('#customerPassword').val());
    return;
  }
  if (!$('#customerName').val()) {
    $('#customerName').addClass('error');
  }
  if (!$('#customerPassword').val()) {
    $('#customerPassword').addClass('error');
  }
}

$(document).ready(() => {
  $('.fadeIn').fadeTo(0, 0);
  $('.menuitem').click(handleClick);
  $('.person').hover(handlePersonOver);
  $('#Kundenbereich th.sort').click(handleSort);
  $('#loginButton').click(handleLogin);
  $('#logoutButton1').click(handleLogout);
  $('#logoutButton2').click(handleLogout);
  if (document.cookie.indexOf('cookie_notice_accepted=true') < 0) {
    $('#CookieBanner').show();
    $('#CookieAccept').click(acceptCookies);
  }

  $('#reload-ReferenzenVideo6').click(() => {
    $('#reload-ReferenzenVideo6').hide(0);
    referenzenVideo6.currentTime = 0;
    referenzenVideo6PlayCounter = 0;
    referenzenVideo6.play();
  });
  $('#reload-ReferenzenVideo5').click(() => {
    $('#reload-ReferenzenVideo5').hide(0);
    referenzenVideo5.currentTime = 0;
    referenzenVideo5.play();
  });
  $('#reload-ReferenzenVideo33IO').click(() => {
    $('#reload-ReferenzenVideo33IO').hide(0);
    referenzenVideo33IO.currentTime = 0;
    referenzenVideo33IO.play();
  });
  $('#reload-ReferenzenVideoIScreen').click(() => {
    $('#reload-ReferenzenVideoIScreen').hide(0);
    referenzenVideoIScreen.currentTime = 0;
    referenzenVideoIScreen.play();
  });
  $('#reload-ReferenzenVideoRepha').click(() => {
    $('#reload-ReferenzenVideoRepha').hide(0);
    referenzenVideoRepha.currentTime = 0;
    referenzenVideoRepha.play();
  });

  handleWindowResize();

  const urlParts = document.URL.split('#');
  if (urlParts.length === 2 && urlParts[1].startsWith('Kundenbereich')) {
    window.location.hash = '#Kundenbereich';
    autoLogin = urlParts[1].split('/');
  } else if (urlParts.length === 2 && urlParts[1].startsWith('Referenzen')) {
    window.location.hash = '#Referenzen';
  } else if (urlParts.length === 1 || urlParts[1] === 'Home') {
    showPage('#Main');
  }
});

window.onload = () => {
  window.onresize = handleWindowResize;
  $(window).scroll(handleScrollFinished);
  const urlParts = document.URL.split('#');
  if (urlParts.length > 0) {
    const page = document.getElementById(urlParts[1]);
    if (page) {
      currentArea = page.getAttribute('data-area');
      currentPage = page.getAttribute('data-anchor');
    }

    if (urlParts[1] === 'Kundenbereich') {
      showPage('#Kundenbereich');
    } else if (urlParts[1] === 'Referenzen') {
      showPage('#Referenzen');
    } else if (urlParts[1] === 'Kontakt' || urlParts[1] === 'Impressum' || urlParts[1] === 'Datenschutz') {
      showPage('#Main-Kontakt');
    } else {
      showPage('#Main');
    }
  }
  $('#IconScroll1').show();
};
